<template>
  <div style="padding-bottom: 200px">
    <form
      @submit.prevent="submitHandler"
      @keyup.enter="handleKeyPress"
    >
      <div class="formgrid p-fluid grid">
        <div class="field col-12">
          <div :class="estimateTitleClass()">
            <label
              style="color: #687a8d !important"
              for="name"
            >Cost Estimate Name</label>
            <Button
              v-if="hideTemplateButton == false"
              label="From template"
              class="p-button-text p-button-primary w-auto p-2"
              icon="las la-plus"
              @click="toggle"
            />
            <OverlayPanel
              ref="op"
              appendTo="body"
              :showCloseIcon="false"
              id="overlay_panel"
              style="min-width: 280px; width: auto; height: auto"
            >
              <div>
                <Dropdown
                  style="min-width: 232.2px"
                  v-model="selectedEstimateTemplate"
                  :options="estimateTemplates"
                  optionLabel="title"
                  :filter="true"
                  placeholder="Select a Template"
                  :showClear="true"
                >
                  <template #value="slotProps">
                    <div v-if="slotProps.value">
                      <div>{{ slotProps.value.title }}</div>
                    </div>
                    <span v-else>
                      {{ slotProps.placeholder }}
                    </span>
                  </template>
                  <template #option="slotProps">
                    <div>
                      <div>{{ slotProps.option.title }}</div>
                    </div>
                  </template>
                </Dropdown>
              </div>
              <div class="flex justify-content-between pt-3 col-12">
                <Button
                  @click="handleTemplate(selectedEstimateTemplate)"
                  label="Apply"
                  type="submit"
                />
                <Button
                  label="Cancel"
                  @click="closeTemplateOverlay()"
                  class="p-button-text p-button-secondary"
                  autofocus
                />
              </div>
            </OverlayPanel>
          </div>
          <div class="p-inputgroup">
            <InputText
              v-model="ce.title"
              placeholder="Cost Estimate Name"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.ce.title.required"
          >
            Cost Estimate Name is required
          </div>
        </div>
        <!-- {{estimate_clients}} -->
        <div class="field col-12 sm:col-6">
          <label for="name">Client</label>
          <div class="p-inputgroup">
            <Dropdown
              placeholder="Select Client"
              v-model="ce.client"
              dataKey="uid"
              :disabled="ce.uid"
              optionLabel="company_name"
              @change="handleContacts(ce.client)"
              :options="estimate_clients"
              v-if="!isLoading"
            />
            <!-- <Dropdown
              v-model="ce.client"
              dataKey="uid"
              optionLabel="company_name"
              @change="handleContacts(ce.client)"
              :options="estimate_clients"
              v-else
            /> -->
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.ce.client.required"
          >
            Client is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label>Contact</label>
          <div class="p-inputgroup">
            <Dropdown
              v-if="estimates_contacts.length > 0"
              :options="estimates_contacts"
              optionLabel="contactDetails.contact_person"
              autocomplete="off"
              dataKey="uid"
              v-model="ce.contact"
              placeholder="Select Contact"
            />
            <Dropdown
              v-else-if="contacts.length > 0"
              :options="contacts"
              optionLabel="contactDetails.contact_person"
              autocomplete="off"
              dataKey="uid"
              v-model="ce.contact"
              placeholder="Select Contact"
            />
            <Dropdown
              v-else
              disabled
              placeholder="No contacts assigned yet"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.ce.contact.required"
          >
            Contact is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label for="name">Terms</label>
          <div class="p-inputgroup">
            <Dropdown
              placeholder="Select Term"
              v-model="ce.term"
              dataKey="uid"
              optionLabel="description"
              :options="estimate_terms"
              v-if="!isLoading"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.ce.term.required"
          >
            Terms is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label for="name">Category</label>
          <div class="p-inputgroup">
            <Dropdown
              placeholder="Select Category"
              v-model="ce.category"
              dataKey="uid"
              optionLabel="description"
              v-if="!isLoading"
              :options="estimate_categories"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.ce.category.required"
          >
            Category is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label class="label">Date</label>
          <div>
            <Calendar
              id="icon"
              autocomplete="off"
              v-model="ce.created_date"
              @input="addValidDate"
              :showIcon="true"
              :selectOtherMonths="true"
              :showOtherMonths="true"
              dateFormat="yy-mm-dd"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.ce.created_date.required"
          >
            Date is required
          </div>
        </div>
        <div class="field col-12 sm:col-6">
          <label class="label">Valid Until</label>
          <div>
            <Calendar
              :minDate="ce.created_date"
              id="icon"
              autocomplete="off"
              v-model="ce.valid_date"
              :showIcon="true"
              :selectOtherMonths="true"
              :showOtherMonths="true"
              dateFormat="yy-mm-dd"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.ce.valid_date.required"
          >
            Valid Until is required
          </div>
        </div>

        <!-- <div class="col-12 sm:col-6 field-checkbox">
          <div
            :class="
              'flex align-items-center' +
              (ce_template ? ' cursor-disable' : '')
            "
          >
            <Checkbox
              id="binary"
              :binary="true"
              @change="!!ce_template ? handleTemplate(ce_template.uid) : ''"
              :disabled="ce_template !== null"
              v-model="is_template"
            />
            <label for="binary" class="font-light cursor-pointer ml-1"
              >Template</label
            >
          </div>
        </div> -->
        <div class="field col-12">
          <label for="name">Description</label>
          <Textarea
            ref="editor"
            v-model="ce.description"
            :autoResize="true"
          ></Textarea>
          <!-- <div>
            <Editor
              ref="editor"
              id="Description"
              :autoResize="true"
              v-model="ce.description"
              editorStyle="height:150px"
            >
              <template #toolbar>
                <span class="ql-formats">
                  <button class="ql-bold"></button>
                  <button class="ql-italic"></button>
                  <button class="ql-underline"></button><button class="ql-strike"></button>
                  <button class="ql-list" value="ordered"></button>
                  <button class="ql-list" value="bullet"></button>
                  <button class="ql-align"></button>
                  <button class="ql-link"></button>
                </span> </template
            ></Editor>
          </div> -->
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.ce.description.required"
          >
            Description is required
          </div>
        </div>
      </div>

      <div class="flex justify-content-between pt-5">
        <div>
          <Button type="submit"> SAVE </Button>
        </div>
        <Button
          class="p-button-text p-button-secondary"
          @click="clearSliderContent"
        >
          CANCEL
        </Button>
      </div>
    </form>
  </div>
</template>
<script>
import { state, fetchCostEstimate } from "../../../services/data_service.js";
import { required } from "vuelidate/lib/validators";
export default {
  name: "JobForm",
  props: {
    ceProp: {
      type: Object,
    },
    isNew: {
      type: Boolean,
    },
  },
  components: {},
  data() {
    return {
      fullEstimateTemplate: [],
      hideTemplateButton: false,
      selectedEstimateTemplate: null,
      submitted: false,
      selectedDepartments: [],
      formOptions: [],
      myFiles: [],
      name: "",
      contacts: [],
      currency: null,
      ce_template: null,
      current_valid_date: null,
      current_date: null,
      client: null,
      contact: null,
      managed_by: null,
      checked: true,
      isLoading: null,
      loadingText: "Loading...",
      inputIsVisible: false,
      ce: {
        title: null,
        ce_number: null,
        uid: null,
        valid_date: null,
        created_date: null,
        status: null,
        description: null,
        term: null,
        client: null,
        category: null,
        contact: null,
        amount: 0,
        groups: null,
        template: null,
      },
    };
  },
  validations: {
    ce: {
      title: {
        required,
      },
      client: {
        required,
      },
      contact: {
        required,
      },
      term: {
        required,
      },
      category: {
        required,
      },
      created_date: {
        required,
      },
      valid_date: {
        required,
      },
      description: {
        required,
      },
    },
  },
  async mounted() {
    if (this.ceProp) {
      this.ceProp.valid_date = this.$moment(this.ceProp.valid_date).format(
        "YYYY-MM-DD"
      );
      this.ceProp.created_date = this.$moment(this.ceProp.created_date).format(
        "YYYY-MM-DD"
      );
      if (this.ceProp.ce_from_template === 1) {
        this.handleTemplate(this.ceProp);
      } else {
        this.client = this.ceProp.client.uid;
        this.contact = this.ceProp.client.contact;
        this.handleContacts(this.client);
        this.ce = JSON.parse(JSON.stringify(this.ceProp));
        this.ce.created_date = new Date(this.ceProp.created_date);
      }
      if (this.ceProp.ce_from_template !== null) {
        if (this.ceProp.ce_from_template == 1) {
          this.hideTemplateButton = true;
        } else if (this.isNew == true) {
          this.hideTemplateButton = false;
        } else {
          this.hideTemplateButton = true;
        }
      }
    } else {
      this.getCurrentDate();
    }
    if (
      !this.ceProp ||
      !this.ceProp.uid ||
      this.ceProp.ce_from_template === 1
    ) {
      await this.getCESeed();
    }
    // this.clients.sort((a, b) => a.name.localeCompare(b.name))
  },
  methods: {
    handleKeyPress(event) {
      const editor = this.$refs.editor;
      if (editor && editor.$el.contains(event.target)) {
        // If "Enter" is pressed inside the editor, create a new line
        event.stopPropagation();
      } else {
        // Else, submit the form
        this.submitHandler();
      }
    },
    getCurrentDate() {
      const d = new Date();
      const year = d.getFullYear();
      const month = d.getMonth();
      const day = d.getDate();
      this.ce.created_date = new Date(year, month, day);
      this.ce.valid_date = this.$moment(this.ce.created_date)
        .add(1, "month")
        .format("YYYY-MM-DD");
    },
    estimateTitleClass() {
      if (this.hideTemplateButton == true) {
        return "flex align-items-center justify-content-between mb-2";
      }
      return "flex align-items-center justify-content-between";
    },
    addValidDate() {
      return (this.ce.valid_date = this.$moment(this.ce.created_date)
        .add(1, "months")
        .format("YYYY-MM-DD"));
    },
    handleTemplate(obj) {
      this.$axios
        .get(process.env.VUE_APP_ROOT_API + "/v1/estimate/" + obj.uid, {})
        .then((response) => {
          this.fullEstimateTemplate = response.data.data;
          this.ce.category = this.fullEstimateTemplate.category;
          this.ce.description = this.fullEstimateTemplate.description;
          this.ce.groups = this.fullEstimateTemplate.groups;
          this.ce.template = this.fullEstimateTemplate.uid;
          this.$refs.op.hide();
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO! You have no power here.");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
      return this.fullEstimateTemplate;
    },
    closeTemplateOverlay() {
      this.$refs.op.hide();
      this.selectedEstimateTemplate = null;
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    getCESeed() {
      this.$axios
        .get(process.env.VUE_APP_ROOT_API + "/v1/estimate/get/seed/", {})
        .then((response) => {
          this.ce.ce_number = response.data.data[0].counter;
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO! You have no power here.");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },

    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        this.$store.dispatch("setSliderLoader", true);

        this.loadingText = "Processing...";

        if (!this.ce.revision) {
          this.ce.revision = 1;
        } else {
          this.ce.revision++;
        }

        if (!this.ce.issued_date) {
          this.ce.issued_date = "";
        }

        if (!this.ce.approved_date) {
          this.ce.approved_date = "";
        }

        if (!this.ce.internal_message) {
          this.ce.internal_message = "";
        }

        if (!this.ce.client_message) {
          this.ce.client_message = "";
        }

        if (this.ce.created_date) {
          this.ce.created_date = this.$moment(this.ce.created_date).format(
            "YYYY-MM-DD"
          );
        }
        if (this.ce.valid_date) {
          this.ce.valid_date = this.$moment(this.ce.valid_date).format(
            "YYYY-MM-DD"
          );
        }
        if (!this.ce.groups) {
          this.ce.groups = [];
        } else {
          var objectAmount = 0;
          if (this.ce.groups && this.ce.groups.length > 0) {
            this.ce.groups.forEach((g) => {
              var totalAmount = 0;
              if (g.items && g.items.length > 0) {
                var amount = 0;
                g.items.forEach((i) => {
                  if (this.ce.uid !== null) {
                    if (i.task_type || i.cost_type) {
                      if ("cost_type" in i && i.cost_type.length > 0)
                        i.cost_type.map(
                          (cost) => (amount += cost.resell_amount * i.quantity)
                        );
                      if ("task_type" in i && i.task_type.length > 0)
                        i.task_type.map(
                          (task) => (amount += task.price * i.quantity)
                        );
                    }
                  }
                  if (i.type === "cost_type") {
                    if (i.cost_type && i.cost_type.length > 0) {
                      i.cost_type.map((c) => {
                        if (c.options) {
                          c.options.map((m) => {
                            if (!m.units) {
                              m.units = parseInt(m.quantity);
                            }
                            if (!m.unit_cost) {
                              m.unit_cost = parseFloat(m.unit_price);
                            }

                            if (!m.markup) {
                              m.markup = parseFloat(m.margin);
                            }
                            if (!m.resell_amount) {
                              m.resell_amount = parseFloat(m.price);
                            }
                          });
                        }
                      });
                    }
                  }
                });
                g.items.amount = amount;
                totalAmount = totalAmount + amount;
              }
              g.totalAmount = totalAmount;
              objectAmount = objectAmount + totalAmount;
            });
          }
          this.ce.amount = objectAmount;
        }

        this.ce.status = this.ce.status ? this.ce.status : "Draft";
        var uid = this.ce ? this.ce.uid : null;
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API +
              "/v1/estimate/" +
              (uid ? "update/" + uid : "create"),
            JSON.stringify(this.ce),
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            const uid = response.data.data;

            this.$store.dispatch("setSliderLoader", false);
            if (!this.ce.uid) {
              this.$store.dispatch("setSliderLoader", false);
              this.clearSliderContent();
              this.$router.push("/finance/estimate/" + uid);
            } else {
              this.clearSliderContent();
            }
          })
          .catch((error) => {
            this.$store.dispatch("setSliderLoader", false);
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
    handleContacts(id) {
      this.estimate_clients.forEach((client) => {
        if (client.uid === id.uid) {
          this.contacts = client.contacts;

          this.contacts.forEach((c) => {
            c.contact_name =
              c.contactDetails.name + " " + c.contactDetails.surname;
          });
        }
      });
    },
    removeUids() {
      this.ce.groups.forEach((g) => {
        g.items.forEach((i) => {
          if (!i.exists) {
            delete i[i.type].uid;
          }
        });
      });
    },
  },
  computed: {
    estimateTemplates() {
      if (state.estimatesSummary) {
        return state.estimatesSummary.filter(
          (estimate) => estimate.is_template == 1
        );
      }
      return null;
    },
    obj() {
      return this.$store.getters.selectedObject;
    },
    categories() {
      return state.categories;
    },
    estimate_terms() {
      return state.estimateTerms;
    },
    estimate_clients() {
      return state.estimateClients;
    },
    estimate_categories() {
      return state.estimateCategories;
    },
    estimates_contacts() {
      if (this.ce.client) {
        var client = this.estimate_clients.find(
          (a) => a.uid === this.ce.client.uid
        );
        if (client) {
          return client.contacts;
        }
        return [];
      }
      return [];
    },
    user() {
      return this.$store.getters.user;
    },
  },
  watch: {},
};
</script>